type LogoSize = 'large' | 'medium' | 'small' | 'avatar' | 'icon';

type CompanyLogoProps = {
  company_icon_url: string;
  size: LogoSize;
} & React.ComponentProps<'div'>;

export default function CompanyLogo({ company_icon_url, size, className }: CompanyLogoProps) {
  let sizeClasses: string;
  switch (size) {
    case 'large': {
      sizeClasses = 'h7 w7';
      break;
    }
    case 'medium': {
      sizeClasses = 'h5 w5';
      break;
    }
    case 'small': {
      sizeClasses = 'h4 w4';
      break;
    }
    case 'avatar': {
      sizeClasses = 'h-28px w-28px';
      break;
    }
    case 'icon': {
      sizeClasses = 'h-4 w-4 w-company-icon h-company-icon br-2px';
    }
  }
  return (
    <div
      className={`border-box bg-cover bg-no-repeat ${sizeClasses} ${className}`}
      style={{ backgroundImage: 'url(' + company_icon_url + ')' }}
    ></div>
  );
}
