import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import axios from '../../../utils/axios';
import { formatUserTimeZoneDate, SuperAdminHyperlinkField } from '../../superAdminFormatters';
import { useUser } from '../../../userContext';
import Banner from '../../../elements/Banner';
import { Tooltip } from 'react-tooltip';
import { DCCourseSessionTable } from './DCCourseSessionTable';
import { TGrantDetails } from '../../../types/GrantDetails';
import { TDCCourseDetails } from '../../../types/DCCourseDetails';
import { TIntakeSurveySubmissionData } from '../../../types/IntakeSurveySubmissionData';

type TDCCourseDetailsSuperAdminTabProps = {
  dcCourseId: string;
};

const IntakeSurveyDetails = (props: { submissionData: TIntakeSurveySubmissionData; dcCourseId: string }) => {
  const { user } = useUser();
  const queryClient = useQueryClient();

  const submission = props?.submissionData;
  if (!submission?.submitted_at) {
    return <em>Not submitted</em>;
  }

  const refetch = () => {
    axios.get<TDCCourseDetails>(`api/dc-courses/refetch/${props.dcCourseId}`).then(() => {
      queryClient.invalidateQueries({ queryKey: ['dcCourse'] });
    });
  };

  return (
    <div>
      <div>
        <span className="font-bold">Submitted at:</span>{' '}
        {formatUserTimeZoneDate(user.timezone, new Date(submission?.submitted_at).getTime())}
      </div>
      <span className="font-bold">Answers:</span>
      <Banner className="my-2 bg-orange-200">
        <span className="font-semibold">Beta alert:</span> Here we have the survey submission answers ... we have some
        work to display the associated questions.{' '}
      </Banner>
      {submission.form_details ? (
        <textarea
          value={JSON.stringify(submission.form_details, null, 2)}
          readOnly={true}
          style={{ width: '100%', height: '400px' }}
        />
      ) : (
        <div>
          <div>Submission question data not available.</div>
          <button className="m-2 rounded-md bg-blue-500 px-4 py-2 text-white hover:bg-blue-600" onClick={refetch}>
            Refetch submission
          </button>
        </div>
      )}
      {submission.answers ? (
        <textarea
          value={JSON.stringify(submission.answers, null, 2)}
          readOnly={true}
          style={{ width: '100%', height: '400px' }}
        />
      ) : (
        'Submission answer data not available'
      )}
    </div>
  );
};

export const DCCourseDetailsSuperAdminTab = (props: TDCCourseDetailsSuperAdminTabProps) => {
  const { user } = useUser();
  const { dcCourseId } = props;
  const queryClient = useQueryClient();
  const [notes, setNotes] = useState(undefined);
  const {
    data: dcCourse,
    isLoading,
    error,
  } = useQuery<TDCCourseDetails, Error>({
    queryKey: ['dcCourse'],
    queryFn: (): Promise<TDCCourseDetails> =>
      axios.get<TDCCourseDetails>(`api/dc-courses/${dcCourseId}`).then((response) => {
        setNotes(response.data?.notes);
        return response.data;
      }),
  });

  const updateDCCourse = () => {
    axios.patch(`api/dc-courses/${dcCourseId}`, { notes }).then(() => {
      queryClient.invalidateQueries({ queryKey: ['dcCourse'] });
    });
  };

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>An error has occurred: {error.message}</div>;

  const allSessionGrants = [
    ...dcCourse.mentee.practice.guaranteed,
    ...(dcCourse.mentee.practice.clawedBack || []),
  ].sort((a: TGrantDetails, b: TGrantDetails) => new Date(a.grantDate).getTime() - new Date(b.grantDate).getTime());

  const allSessionsCount =
    (dcCourse.mentee.practice?.guaranteed?.length ?? 0) + (dcCourse.mentee.practice?.clawedBack?.length ?? 0);
  const completedSessions = dcCourse.mentee.practice.guaranteed?.filter(
    (grant: TGrantDetails) => grant.interview?.status === 'TERMINATED'
  ); // TODO: This should prob be a status in the list of conclusive types
  const scheduledSessions = dcCourse.mentee.practice.guaranteed?.filter(
    (grant: TGrantDetails) => grant.round?._id && !grant.interview?._id
  );

  const clawedBackCount = dcCourse.mentee.practice?.clawedBack?.length ?? 0;
  const completedCount = completedSessions.length;
  const scheduledCount = scheduledSessions.length;
  const unscheduledCount = dcCourse.mentee.practice.guaranteed?.filter(
    (grant: TGrantDetails) => !grant.round?._id
  ).length;

  const lastCompletedSession = completedSessions.sort(
    (a: TGrantDetails, b: TGrantDetails) => new Date(a.round.start).getTime() - new Date(b.round.start).getTime()
  )?.[0];
  const nextScheduledSession = scheduledSessions.sort(
    (a: TGrantDetails, b: TGrantDetails) => new Date(b.round.start).getTime() - new Date(a.round.start).getTime()
  )?.[0];

  return (
    <div className="my-2 mt-4 flex flex-col">
      <a href="/admin/dc-courses">{'<'} DC Courses</a>
      <h2 className="mt-4">DC Courses</h2>
      <div>
        <span className="font-bold">Course id:</span> {dcCourseId}
      </div>
      <div>
        <a href={`/admin/users?userId=${dcCourse.mentee._id}`}>{dcCourse.mentee.email}</a>
      </div>
      <div>
        <span className="font-bold">Status:</span> {dcCourse.status}
      </div>
      <h3 className="mt-3">Course summary - at purchase / creation</h3>
      <div>
        <span className="font-bold">Org:</span>{' '}
        {dcCourse.org ? (
          <SuperAdminHyperlinkField
            anchorHref={`/admin/org/?orgId=${dcCourse.org._id}`}
            anchorText={dcCourse.org.companyName}
            clipboardValue={dcCourse.org._id}
            clipboardSuccessMessage={`Org Id for "${dcCourse.org.companyName}" copied to clipboard`}
          />
        ) : (
          'Not set'
        )}
      </div>
      <div>
        <span className="font-bold">Focus:</span> {dcCourse.focus ?? 'Not set'}
      </div>
      <div>
        <span className="font-bold">Sessions:</span> {dcCourse.sessions ?? 'Not set'}
      </div>
      <h3 className="mt-3">Session activity</h3>
      <div>
        <span className="font-bold" data-tooltip-id="session-count-tooltip" data-tooltip-content="Last ">
          Last completed:
        </span>{' '}
        {lastCompletedSession ? (
          <>
            <SuperAdminHyperlinkField
              anchorHref={`/admin/rounds/?roundId=${lastCompletedSession.round._id}`}
              anchorText={formatUserTimeZoneDate(user.timezone, new Date(lastCompletedSession.round.start).getTime())}
              clipboardValue={lastCompletedSession.round._id}
              clipboardSuccessMessage={`Round Id "${lastCompletedSession.round._id}" copied to clipboard`}
            />{' '}
            ( Feedback:{' '}
            <SuperAdminHyperlinkField
              anchorHref={`/feedback/${lastCompletedSession.interview.friendlyId}`}
              anchorText={lastCompletedSession.interview.friendlyId}
              clipboardValue={lastCompletedSession.interview.friendlyId}
              clipboardSuccessMessage={`Friendly Id "${lastCompletedSession.interview.friendlyId}" copied to clipboard`}
            />
            )
          </>
        ) : (
          'N/A'
        )}
      </div>
      <div>
        <span className="font-bold">Next scheduled:</span>{' '}
        {nextScheduledSession ? (
          <SuperAdminHyperlinkField
            anchorHref={`/admin/rounds/?roundId=${nextScheduledSession.round._id}`}
            anchorText={formatUserTimeZoneDate(user.timezone, new Date(nextScheduledSession.round.start).getTime())}
            clipboardValue={nextScheduledSession.round._id}
            clipboardSuccessMessage={`Round Id "${nextScheduledSession.round._id}" copied to clipboard`}
          />
        ) : (
          'N/A'
        )}
      </div>
      <h3 className="mt-3"> Notes </h3>
      <div>
        <textarea value={notes} onChange={(e) => setNotes(e.target.value)}></textarea>
        {String(dcCourse.notes) !== String(notes) && (
          <button className="rounded-md bg-blue-500 px-4 py-2 text-white hover:bg-blue-600" onClick={updateDCCourse}>
            Save changes
          </button>
        )}
      </div>
      <Tooltip id="session-count-tooltip" />
      <h3 className="mt-3">Session breakdown</h3>
      <div>
        <span className="font-bold" data-tooltip-id="session-count-tooltip" data-tooltip-content="All sessions">
          All sessions:
        </span>{' '}
        {allSessionsCount}
      </div>
      <div data-tooltip-id="session-count-tooltip">
        <span className="font-bold" data-tooltip-id="session-count-tooltip" data-tooltip-content="Completed">
          Completed:
        </span>{' '}
        {completedCount}
      </div>
      <div data-tooltip-id="session-count-tooltip">
        <span className="font-bold" data-tooltip-id="session-count-tooltip" data-tooltip-content="Scheduled">
          Scheduled:
        </span>{' '}
        {scheduledCount}
      </div>
      <div data-tooltip-id="session-count-tooltip">
        <span className="font-bold" data-tooltip-id="session-count-tooltip" data-tooltip-content="Unscheduled">
          Unscheduled:
        </span>{' '}
        {unscheduledCount}
      </div>
      <div data-tooltip-id="session-count-tooltip">
        <span className="font-bold" data-tooltip-id="session-count-tooltip" data-tooltip-content="Clawed back">
          Clawed back:
        </span>{' '}
        {clawedBackCount}
      </div>
      <DCCourseSessionTable sessions={allSessionGrants} dcCourse={dcCourse} />
      <h3 className="mt-3">Intake survey</h3>
      <IntakeSurveyDetails submissionData={dcCourse.intakeSurveySubmission?.data} dcCourseId={dcCourseId} />
    </div>
  );
};

export default DCCourseDetailsSuperAdminTab;
