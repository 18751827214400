import { ReactNode } from 'react';

type IconProps = {
  children: ReactNode; // material icon type e.g., "verified_user"
} & React.ComponentProps<'div'>;

export default function Icon({ children, className }: IconProps) {
  return (
    <div className={className}>
      <div className="material-icons text-xs">{children}</div>
    </div>
  );
}
