import { DCCourseProducts, OrderItemDetails } from '../../../types/DCAddSessionTypes';
type DCPaidSessionRowProps = {
  dcCourseProducts: DCCourseProducts;
  onChangeDetails: (arg0: OrderItemDetails) => void;
  clickedRemoveOrderItem: () => void;
} & OrderItemDetails;
export const DCPaidSessionRow = (props: DCPaidSessionRowProps) => {
  const {
    orgId,
    focus,
    quantity,
    dcCourseProducts: { orgNamesById, focusesForOrg, unitPriceByOrgAndFocus },
    onChangeDetails,
    clickedRemoveOrderItem,
  } = props;

  const focuses = Array.from(focusesForOrg[orgId]);
  const focusThatOrgAllows = focuses.includes(focus) ? focus : focuses[0];

  const quantityValue = quantity || 1;

  const unitPriceKey = `${orgId}${focusThatOrgAllows}`;
  const unitPrice = unitPriceByOrgAndFocus[unitPriceKey];
  const price = `$${(unitPrice * quantityValue) / 100}`;

  return (
    <div className="flex items-center space-x-2">
      <div>
        <label className="whitespace-nowrap">Org </label>
        <select value={orgId} onChange={(e) => onChangeDetails({ orgId: e.target.value, focus: focusThatOrgAllows })}>
          {Object.entries(orgNamesById).map(([orgId, name]: [string, string]) => (
            <option key={orgId} value={orgId}>
              {name}
            </option>
          ))}
        </select>
      </div>

      <div>
        <label className="whitespace-nowrap">Focus</label>
        <select
          value={focusThatOrgAllows}
          onChange={(e) => onChangeDetails({ focus: e.target.value })}
          disabled={!orgId}
        >
          {focuses.map((value: string) => (
            <option key={value} value={value}>
              {value}
            </option>
          ))}
        </select>
      </div>

      <div>
        <label className="whitespace-nowrap">
          <span>Sessions to add</span>
        </label>
        <input
          value={quantityValue}
          type="number"
          id="sessions"
          name="sessions"
          step="1"
          min="1"
          onChange={(e) => onChangeDetails({ quantity: Number(e.target.value) })}
        />
      </div>

      <div>{price}</div>

      <div className="text-2xl text-red-500" onClick={clickedRemoveOrderItem}>
        X
      </div>
    </div>
  );
};
