import axios from '../../../utils/axios';

import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';

type TAddSessionResult = {
  error?: unknown;
  dcCourseId?: unknown;
  menteeId?: unknown;
  mentorOrgId?: unknown;
} | null;

export const DCAddUnpaidSessionsForm = (props: { dcCourseId?: string; menteeId?: string; email?: string }) => {
  const { dcCourseId, menteeId, email } = props;

  const [mentorOrgId, setMentorOrgId] = useState('');

  const { data: orgs }: { data: { companyName: string; _id: string }[] } = useQuery({
    queryKey: ['dcCourseOrgs'],
    queryFn: () =>
      axios.get('/api/dc-courses/dc-course-orgs').then((response) => {
        setMentorOrgId(response.data?.[0]._id);
        return response.data;
      }),
  });

  const dcFocusValues = window.CONFIG.const.ROUND_FOCUS_VALUES.filter((v: string) => v.match(/prep/));
  const [numSessions, setNumSessions] = useState(1);
  const [focus, setFocus] = useState(dcFocusValues[0]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [result, setResult]: [TAddSessionResult, (arg0: TAddSessionResult) => void] = useState(null);
  const submitForm = () => {
    axios
      .post(`/api/dc-courses/${dcCourseId}/dc-sessions`, {
        action: 'add-sessions',
        menteeId,
        dcCourseId,
        focus,
        mentorOrgId,
        numSessions,
      })
      .then((response) => {
        setResult(response.data);
      })
      .catch((error: unknown) => {
        setResult({ error });
      });
  };

  if (result) {
    if (result.error) {
      return (
        <>
          <div className="red mt-8"> Error </div>
          <div>
            {' '}
            <a href="/admin/dc-courses"> Return to DC Dashboard</a>{' '}
          </div>
        </>
      );
    } else {
      return (
        <div className="flex-column flex gap-8">
          <h2 className="mt-8">Success! Added sessions to DC Course for user {email || menteeId} </h2>
          <a href={'/admin/dc-courses/' + dcCourseId}> Go back to course page </a>
          <a href={'/admin/orgs?orgId=' + mentorOrgId}> Go to mentor org page</a>
          <a href={'/admin/users?userId=' + menteeId}> Go to to mentee's user page</a>
        </div>
      );
    }
  }

  return (
    <>
      <h2 className="mt-8">Adding DC session</h2>
      <div className="flex-column card mt-4 flex max-w-prose gap-4 rounded border border-gray-200 p-2">
        {email && (
          <>
            <label className="whitespace-nowrap">Mentee email</label>
            <input type="text" readOnly value={email} size={email.length}></input>
          </>
        )}
        {menteeId && (
          <>
            <label className="whitespace-nowrap">Mentee Id</label>
            <input type="text" readOnly value={menteeId} size={menteeId.length}></input>
          </>
        )}
        <label className="space-between gap-4 ">
          <span>Sessions to add</span>
          <input
            value={numSessions}
            onChange={(e) => setNumSessions(Number(e.target.value))}
            type="number"
            id="sessions"
            name="sessions"
            step="1"
            min="1"
          />
        </label>
        <label>Focus</label>
        <select value={focus} onChange={(e) => setFocus(e.target.value)}>
          {dcFocusValues.map((v: string) => (
            <option key={v} value={v}>
              {v}
            </option>
          ))}
        </select>

        <label>Mentor</label>
        <select value={mentorOrgId} onChange={(e) => setMentorOrgId(e.target.value)}>
          {orgs &&
            orgs.map((org: { _id: string; companyName: string }) => (
              <option key={org._id} value={org._id}>
                {org.companyName}
              </option>
            ))}
        </select>

        <button onClick={submitForm} className="m-auto rounded p-1 text-lg font-bold text-white">
          Add sessions
        </button>
      </div>
    </>
  );
};
