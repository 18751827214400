enum BasicRichTextComponentAlignment {
  left,
  center,
  right,
}

export enum ComponentTypes {
  basicRichTextComponentLabel = 'basic.rich-text',
  sectionsRelatedBookSection = 'sections.related-book-section',
  bookPanelsDocumentDownload = 'book-panels.document-download',
}

export type TBasicRichTextStrapiComponent = {
  id: number;
  __component: ComponentTypes.basicRichTextComponentLabel;
  RichText: string;
  Alignment: BasicRichTextComponentAlignment;
};
