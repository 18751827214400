import Icon from '../Icon/Icon';
import clsx from 'clsx';

const alertTypes = {
  WHITE: 'white',
  WARNING: 'warning',
  SUCCESS: 'success',
  ERROR: 'error',
  INFO: 'info',
  GRAY: 'gray',
} as const;

type AlertType = typeof alertTypes[keyof typeof alertTypes];

type BadgeProps = {
  icon: string; // material icon
  alertType: AlertType;
  fixed?: boolean;
  isNew?: boolean;
  children?: React.ReactNode;
} & React.ComponentProps<'div'>;

export default function Badge({ icon, alertType, fixed, isNew, children, className }: BadgeProps) {
  // TODO: Remove all the Angular CSS/LESS from this component and only use Tailwind classes.
  const classes = clsx(
    `_badge-${alertType || 'default'}`,
    fixed && '_badge-fixed',
    'justify-center br5 py-0.5',
    className
  );
  return (
    <div className="flex flex-col lg:flex-row">
      {!isNew ? (
        <div className={classes}>
          {icon && <Icon className="w-100 w-auto-ns o-90 f-11px">{icon}</Icon>}
          <span className="fw7 f-xs ph1">{children}</span>
        </div>
      ) : (
        <div className={clsx(classes, '_badge-new')}>
          <span className="f9">{children || 'New'}</span>
        </div>
      )}
    </div>
  );
}
