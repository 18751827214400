import { Tooltip } from 'react-tooltip';
import Badge from '../Badge/Badge';
import { RoundCTAProps, Round } from '../../types/RoundTypes';
import CompanyLogo from '../CompanyLogo/CompanyLogo';
import { useEffect, useState } from 'react';
import RoundPartnerDescription from '../RoundPartnerDescription/RoundPartnerDescription';

const getRoundFocusDescription = async (round: Round) => {
  let result = null;
  if (window?.angular) {
    const focus = round.details.focus ? round.details.focus : window?.CONFIG.const.ROUND_FOCUS_DEFAULT;
    result = await window.angular
      ?.element(document.body)
      .injector()
      .get('UserService')
      .getRoundFocusDescriptions(focus);
  }
  return result;
};

export default function RoundCTA({ round, showCompany, showClaimable, showTypeOfInterviewerBadge }: RoundCTAProps) {
  const isPeerToPeerPracticeInterview = round._isPeerToPeerPractice;
  const isPremiumPracticeInterview = round._isNonBrandedPremiumPractice || round._isBrandedPremiumPractice;

  const [roundFocusDescription, setRoundFocusDescription] = useState(null);
  useEffect(() => {
    const fetchDescription = async () => {
      try {
        const focusDescription = await getRoundFocusDescription(round);
        setRoundFocusDescription(focusDescription);
      } catch (err) {
        window.Rollbar.error(err);
      }
    };
    fetchDescription();
  }, []);
  return (
    <>
      {round._isPending && (
        <span className="_row o-50">
          <Badge className="mr1" alertType="success" icon="verified_user" fixed={true}>
            Pending
          </Badge>{' '}
          Interview with {round.details.companyName}
        </span>
      )}
      {!round._isPending && (
        <span>
          <div>
            {round._companyRoundUI && (
              <span>
                {round._roleAllowed === 'interviewee' && (
                  <span>
                    <strong>{round.details.companyName}</strong> is interviewing you
                    {round._positionTitleWithLocations && (
                      <span>
                        for the role of
                        <strong>{round._positionTitleWithLocations}</strong>
                      </span>
                    )}
                  </span>
                )}

                {round._roleAllowed === 'interviewer' && (
                  <span>
                    You are interviewing <strong>{round._partnerName}</strong>
                    {round._positionTitleWithLocations && (
                      <span ng-if="round._positionTitleWithLocations">
                        for the role of <strong>{round._positionTitleWithLocations}</strong>
                        {showCompany && (
                          <span>
                            at <strong>{round.details.companyName}</strong>
                          </span>
                        )}
                      </span>
                    )}
                    {!round._positionTitleWithLocations && showCompany && (
                      <span>
                        for
                        <strong>{round.details.companyName}</strong>
                      </span>
                    )}
                  </span>
                )}
              </span>
            )}

            {!round._companyRoundUI && (
              <span>
                <span className="flex-column flex items-center md:flex-row">
                  {round._roleAllowed === 'interviewer' &&
                    !(round._previousFriendlyIds && round._previousFriendlyIds.length) && (
                      <span className="nbsp-after pointer-none">You are interviewing</span>
                    )}
                  {round._roleAllowed === 'interviewer' &&
                    round._previousFriendlyIds &&
                    round._previousFriendlyIds.length && (
                      <span className="nbsp-after pointer-none">You are rematching</span>
                    )}
                  {!round._roleAllowed ||
                    (round._roleAllowed === 'interviewer' && (
                      <span className="_row" yoe-badge>
                        {round._partnerName}
                      </span>
                    ))}
                  {round._roleAllowed === 'interviewee' && (
                    <RoundPartnerDescription
                      round={round}
                      tooltipId="round-partner-description"
                      tooltipPlace="bottom"
                      className="flex-column flex items-center sm:flex-row md:flex-row"
                    >
                      {showTypeOfInterviewerBadge && (
                        <span className="type-of-interviewer-badge-container">
                          {showTypeOfInterviewerBadge && (
                            <span className="type-of-interviewer-badge-container">
                              {isPremiumPracticeInterview && (
                                <span className="nudge-down">
                                  <Badge icon="verified_user" alertType="success" fixed={true}>
                                    Pro
                                  </Badge>
                                </span>
                              )}
                              {isPeerToPeerPracticeInterview && (
                                <span className="nudge-down">
                                  <Badge icon="person" alertType="gray" fixed={true}>
                                    Peer
                                  </Badge>
                                </span>
                              )}
                            </span>
                          )}
                        </span>
                      )}
                      <span className="fw7 underline-faded ml-0 mt-2 underline sm:mt-0 sm:ml-2 md:mt-0 md:ml-2">
                        {round._partnerName}
                      </span>
                    </RoundPartnerDescription>
                  )}
                  {round._roleAllowed === 'interviewee' && (
                    <span className="nbsp-before pointer-none">is interviewing you</span>
                  )}
                  <span className="dib nbsp-before nbsp-after pointer-none">for</span>
                  {isPremiumPracticeInterview && (
                    <RoundPartnerDescription
                      round={round}
                      className="_row nbsp-after"
                      tooltipPlace="bottom"
                      tooltipId="round-partner-description"
                      alternateContent={round._companyName}
                    >
                      <span className="_row br-100 ba b--white overflow-hidden">
                        <CompanyLogo
                          className="lh-none flex"
                          company_icon_url={round.details.companyIconUrl}
                          size="icon"
                        />
                      </span>
                      <span className="_row nbsp-before fw7 o-40 pointer-none">/</span>
                    </RoundPartnerDescription>
                  )}
                  {!isPremiumPracticeInterview && round._companyName && (
                    <RoundPartnerDescription
                      className="_row nbsp-after"
                      round={round}
                      tooltipId="round-partner-description"
                      tooltipPlace="bottom"
                    >
                      <span className="fw7 underline">{round._companyName}</span>
                      <span className="_row nbsp-before fw7 o-40 pointer-none">/</span>
                    </RoundPartnerDescription>
                  )}
                  {round.details.focus && (
                    <>
                      <strong
                        ng-if="round.details.focus"
                        className="dib underline-faded cursor-default underline"
                        data-tooltip-content={roundFocusDescription}
                        data-tooltip-place="bottom"
                        data-tooltip-id="round-focus-description"
                      >
                        {window?.CONFIG.const.ROUND_CTA_FOCUS_DISPLAY_VALUES[round.details.focus]}
                      </strong>
                      <Tooltip id="round-focus-description" opacity={1} style={{ backgroundColor: '#000' }} />
                    </>
                  )}
                  <span className="dib nbsp-before pointer-none">practice</span>{' '}
                  {showClaimable && round._roleAllowed === 'interviewer' && round.claimableWhileAssigned && (
                    <span>
                      (<a href={`/claim/${round._id}`}>claimable</a>)
                    </span>
                  )}
                </span>
              </span>
            )}
          </div>
          {round._previousFriendlyIds && round._previousFriendlyIds.length && (
            <div>
              <span>See previous interviews: </span>
              {round._previousFriendlyIds.map((friendlyId) => (
                <span key={friendlyId}>
                  <u>
                    <a
                      style={{ color: '#686767' }}
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`/feedback/${friendlyId}`}
                    >
                      {friendlyId}
                    </a>
                  </u>
                </span>
              ))}
            </div>
          )}
        </span>
      )}
    </>
  );
}
