import { Tooltip, PlacesType } from 'react-tooltip';
import { Round } from '../../types/RoundTypes';
import { useEffect, useState } from 'react';

type RoundPartnerDescriptionProps = {
  round: Round;
  tooltipId: string;
  tooltipPlace: PlacesType;
  alternateContent?: string;
} & React.ComponentProps<'span'>;

type PartnerDescriptionConfig = {
  interviewer: string | null;
  interviewee: string | null;
};

const getPartnerDescriptionConfig = (round: Round): PartnerDescriptionConfig => {
  if (round._isPeerToPeerPractice) {
    return {
      interviewer: 'Peer Interviewer',
      interviewee: null,
    };
  }
  if (round._isBrandedPremiumPractice) {
    return {
      interviewer: round._companyName, // using roundDetails.companyName here assumes that company name will be structured something like "Professional interviewer at {{companyName}}",
      interviewee: 'Your partner requested: ' + round._companyName,
    };
  }
  if (round._isNonBrandedPremiumPractice) {
    return {
      interviewer: round._companyName,
      interviewee: null,
    };
  }
};

const getPartnerDescription = async (round: Round): Promise<string | null> => {
  const config = getPartnerDescriptionConfig(round);
  let roundPartnerRole: null;

  if (window?.angular) {
    roundPartnerRole = await window.angular
      ?.element(document.body)
      .injector()
      .get('InterviewService')
      .getOtherRole(round._roleAllowed);
  }

  if (!roundPartnerRole || !(roundPartnerRole in config)) {
    return null;
  }
  return config[roundPartnerRole];
};

export default function RoundPartnerDescription({
  round,
  tooltipId,
  tooltipPlace,
  className,
  children,
  alternateContent,
}: RoundPartnerDescriptionProps) {
  const [roundPartnerDescription, setRoundPartnerDescription] = useState(null);
  useEffect(() => {
    const fetchDescription = async (round: Round) => {
      try {
        let description = await getPartnerDescription(round);
        if (!description && alternateContent) {
          description = alternateContent;
        }
        setRoundPartnerDescription(description);
      } catch (err) {
        window.Rollbar.error(err);
      }
    };
    fetchDescription(round);
  }, []);
  return (
    <span
      className={className}
      data-tooltip-id={tooltipId}
      data-tooltip-content={roundPartnerDescription}
      data-tooltip-place={tooltipPlace}
    >
      {children}
      <Tooltip id={tooltipId} opacity={1} style={{ backgroundColor: '#000' }} />
    </span>
  );
}
