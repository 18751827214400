import DCCourseDetailsTab from './DCCourseDetailsTab';
import DCCourseListTab from './DCCourseListTab';
import { DCAddPaidSessionsForm } from './DCAddPaidSessionsForm';
import { DCAddUnpaidSessionsForm } from './DCAddUnpaidSessionsForm';
import { DCRelocateSessionsForm } from './DCRelocateSessionsForm';

export const DCCoursesSuperAdminTab = (props: Record<string, unknown>) => {
  const {
    detail,
    dcCourseId,
    menteeId,
    email,
    sessionIds,
    sessionAction,
  }: {
    detail?: string;
    dcCourseId?: string;
    menteeId?: string;
    email?: string;
    sessionIds?: string[] | string;
    sessionAction?: string;
  } = props;

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">DC Courses</h1>
          <p className="mt-2 text-sm text-gray-700">
            Multiple sessions with multiple mentors to help a user reach their goals.
          </p>
          {sessionAction === 'add-paid-sessions' && (
            <DCAddPaidSessionsForm dcCourseId={dcCourseId} menteeId={menteeId} email={email} />
          )}
          {sessionAction === 'add-unpaid-sessions' && (
            <DCAddUnpaidSessionsForm dcCourseId={dcCourseId} menteeId={menteeId} email={email} />
          )}
          {sessionAction === 'relocate-sessions' && (
            <DCRelocateSessionsForm dcCourseId={dcCourseId} menteeId={menteeId} sessionIds={sessionIds} />
          )}
          {detail && <DCCourseDetailsTab dcCourseId={detail} />}
          {!detail && !sessionAction && <DCCourseListTab />}
        </div>
      </div>
    </div>
  );
};

export default DCCoursesSuperAdminTab;
