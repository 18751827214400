import { Suspense, lazy, useRef, useState } from 'react';
import type {
  ColDef,
  ColGroupDef,
  GridOptions,
  GridReadyEvent,
  ITooltipParams,
  SelectionChangedEvent,
} from 'ag-grid-community';
import { UserTimezoneDateFormatter, SuperAdminHyperlinkCellRenderer } from '../../superAdminFormatters';
import { useUser } from '../../../userContext';
import { TGrantDetails } from '../../../types/GrantDetails';
import { TDCCourseSummary } from '../../../types/DCCourseSummary';

const LazyLoadedAGGrid = lazy(() => import('../../../elements/LazyLoadedAGGrid'));

type TDCCourseSessionTableProps = { sessions: TGrantDetails[]; dcCourse: TDCCourseSummary };

export const DCCourseSessionTable = ({ sessions, dcCourse }: TDCCourseSessionTableProps) => {
  const { user } = useUser();
  const gridApi = useRef(null);

  const [selectedSessions, setSelectedRows] = useState([]);

  const onGridReady = (params: GridReadyEvent) => {
    gridApi.current = params.api;
  };

  const gridOptions: GridOptions = {
    alwaysShowHorizontalScroll: true,
    alwaysShowVerticalScroll: true,
    enableCellTextSelection: true,
    rowSelection: { mode: 'multiRow', checkboxes: true, headerCheckbox: true },
  };

  const updateSelectedSessions = (event: SelectionChangedEvent) => {
    const rows = event.api.getSelectedRows().map(({ _id }) => _id);
    setSelectedRows(rows);
  };

  const exportToCsv = () => gridApi.current?.exportDataAsCsv();
  const columnDefs: (ColDef | ColGroupDef)[] = [
    {
      cellRenderer: SuperAdminHyperlinkCellRenderer<TGrantDetails, TGrantDetails['_id']>(
        (value) => value,
        (data) => `/admin/users/session-grants/${data._id}`,
        (data) => data._id,
        () => `Grant Id for this session copied to clipboard`
      ),
      field: '_id',
      headerName: 'Grant Id',
      sortable: true,
    },

    {
      cellRenderer: UserTimezoneDateFormatter(user.timezone),
      field: 'grantDate',
      filter: true,
      headerName: 'Grant creation date',
      headerTooltip: 'DC Course creation time',
      sortable: true,
      tooltipValueGetter: (p: ITooltipParams) => p.value,
    },
    { headerName: 'State: active or clawed back' },
    {
      cellRenderer: SuperAdminHyperlinkCellRenderer<TGrantDetails, TGrantDetails['round']['_id']>(
        (value) => value,
        (data) => `/admin/rounds?roundId=${data.round._id}&activeTab=0`,
        (data) => data.round._id,
        () => `Round Id for this session copied to clipboard`
      ),
      field: 'round._id',
      headerName: 'Round Id',
      sortable: true,
    },
    {
      cellRenderer: SuperAdminHyperlinkCellRenderer<TGrantDetails, TGrantDetails['interview']['friendlyId']>(
        (value) => value,
        (data) => `/admin/interviews?friendlyId=${data.interview.friendlyId}`,
        (data) => data.interview.friendlyId,
        () => `Interview Id for this session copied to clipboard`
      ),
      field: 'interview.friendlyId',
      headerName: 'Interview Friendly Id',
      sortable: true,
    },
    { headerName: 'Payment Intent Id', field: '' },
  ];

  return (
    <>
      <div className="w-100 flex justify-between">
        <h3 className="mt-3">Session grants</h3>
        <DCSessionActions
          mentee={dcCourse.mentee}
          dcCourseId={String(dcCourse._id)}
          selectedSessions={selectedSessions}
        />
      </div>
      <Suspense fallback={<div className="my-4">Loading...</div>}>
        <LazyLoadedAGGrid
          onGridReady={onGridReady}
          gridOptions={gridOptions}
          className="ag-theme-alpine my-4 text-xs"
          style={{ height: '300px' }}
          columnDefs={columnDefs}
          rowData={sessions}
          onSelectionChanged={updateSelectedSessions}
        />
      </Suspense>
      <div className="my-2 flex flex-col gap-2">
        <div>
          <button className="rounded-md bg-blue-500 px-4 py-2 text-white hover:bg-blue-600" onClick={exportToCsv}>
            Export to CSV
          </button>
        </div>
      </div>
    </>
  );
};

export function DCSessionActions(props: {
  mentee: { email: string; _id: string };
  dcCourseId: string;
  selectedSessions: string[];
}) {
  const {
    mentee: { _id: menteeId, email },
    dcCourseId,
    selectedSessions,
  } = props;

  return (
    <span className="isolate inline-flex shadow-sm">
      <button
        type="button"
        className="relative inline-flex items-center rounded-l-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
        style={{ backgroundColor: 'white' }}
        onClick={() => {
          location.href = `/admin/dc-courses?sessionAction=add-paid-sessions&menteeId=${String(
            menteeId
          )}&dcCourseId=${dcCourseId}&email=${email}`;
        }}
      >
        Purchase Sessions
      </button>
      <button
        type="button"
        className="relative inline-flex items-center bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
        style={{ backgroundColor: 'white' }}
        onClick={() => {
          location.href = `/admin/dc-courses?sessionAction=add-unpaid-sessions&menteeId=${String(
            menteeId
          )}&dcCourseId=${dcCourseId}&email=${email}`;
        }}
      >
        Add Sessions
      </button>

      <button
        type="button"
        className="relative inline-flex items-center rounded-r-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
        style={{ backgroundColor: 'white' }}
        onClick={() => {
          location.href =
            `/admin/dc-courses?sessionAction=relocate-sessions&menteeId=${String(menteeId)}&dcCourseId=${dcCourseId}` +
            selectedSessions.map((sessionId) => `&sessionIds=${sessionId}`).join('');
        }}
        disabled={selectedSessions.length === 0}
      >
        Relocate
      </button>
    </span>
  );
}
