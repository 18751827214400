import { useReducer } from 'react';
import { useProductCatalog } from '../../../hooks/useProductCatalog';

import { DCPaidSessionRow } from './DCPaidSessionRow';
import { DCAddSessionPayment } from './DCAddSessionPayment';

import { DCCourseProducts, OrderItemDetails, OrderItem, OrderModification } from '../../../types/DCAddSessionTypes';

type DCAddOrderRowProps = { clickedAddOrderRow: () => void };
export const DCAddOrderRow = ({ clickedAddOrderRow }: DCAddOrderRowProps) => (
  <div className="text-white">
    <button
      onClick={clickedAddOrderRow}
      className="font-small inline-flex w-max justify-center rounded-md border border-transparent bg-blue-500 px-4 py-2 text-base text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:text-sm"
    >
      Add item
    </button>
  </div>
);
let startingOrder: OrderItemDetails;
let nextOrderRowId = 1;

const orderDetailsReducer = (orderDetails: OrderItem[], action: OrderModification) => {
  if (!startingOrder) {
    startingOrder = orderDetails[0];
  }
  switch (action.type) {
    case 'AddOrderItem': {
      return [...orderDetails, { ...startingOrder, key: nextOrderRowId++ }];
    }
    case 'ChangeDetails': {
      return orderDetails.map((orderRow: OrderItem) => {
        const newFocus = action.focus || orderRow.focus;
        const newQuantity = action.quantity || orderRow.quantity;
        const newOrgId = action.orgId || orderRow.orgId;

        if (orderRow.key !== action.key) return orderRow;
        return { ...orderRow, focus: newFocus, orgId: newOrgId, quantity: newQuantity };
      });
    }
    case 'RemoveOrderItem': {
      return orderDetails.filter((orderRow: OrderItem) => orderRow.key !== action.key);
    }
    default: {
      throw new Error(`Unknown action type ${action.type}`);
    }
  }
};
type DCOrderDetailsProps = { menteeId: string; email: string; dcCourseId: string; dcCourseProducts: DCCourseProducts };
export const DCOrderDetails = (props: DCOrderDetailsProps) => {
  const { menteeId, email, dcCourseId, dcCourseProducts } = props;
  const { orgNamesById, focusesForOrg } = dcCourseProducts;

  // Set starting values for cart
  const startingOrgId = Object.keys(orgNamesById)[0];
  const startingFocusValue = Array.from(focusesForOrg[startingOrgId])[0];
  const startingOrderDetails = { key: 0, orgId: startingOrgId, focus: startingFocusValue, quantity: 1 };
  const [orderDetails, dispatch] = useReducer(orderDetailsReducer, [startingOrderDetails]);

  return (
    <div className="my-4 flex max-w-2xl flex-col gap-y-4">
      <h2>Purchase DC Session(s)</h2>
      <div className="flex items-center justify-between gap-x-1">
        {email && (
          <>
            <label className="whitespace-nowrap">Mentee email</label>
            <input type="text" readOnly value={email} size={email.length}></input>
          </>
        )}
        {menteeId && (
          <>
            <label className="whitespace-nowrap">Mentee Id</label>
            <input type="text" readOnly value={menteeId} size={menteeId.length}></input>
          </>
        )}

        <div className="self-end">
          <DCAddOrderRow clickedAddOrderRow={() => dispatch({ type: 'AddOrderItem' })} />
        </div>
      </div>

      <div className="flex-column flex gap-y-4">
        {orderDetails.map(({ key, orgId, quantity, focus }: OrderItem) => (
          <DCPaidSessionRow
            key={key}
            dcCourseProducts={dcCourseProducts}
            focus={focus}
            orgId={orgId}
            quantity={quantity}
            onChangeDetails={(details: OrderItemDetails) => dispatch({ type: 'ChangeDetails', ...details, key })}
            clickedRemoveOrderItem={() => dispatch({ type: 'RemoveOrderItem', key })}
          />
        ))}
        <DCAddSessionPayment {...props} orderDetails={orderDetails} />
        <span>
          <a href={`/admin/dc-courses/${dcCourseId}`}> Back to course </a>
        </span>
      </div>
    </div>
  );
};

type AddSessionsParams = { dcCourseId?: string; menteeId?: string; email?: string };
export const DCAddPaidSessionsForm = (props: AddSessionsParams) => {
  const { email, menteeId, dcCourseId } = props;

  // Get session detail options from product catalog
  const { data: productCatalog } = useProductCatalog();
  const dcCourseProducts: DCCourseProducts = { orgNamesById: {}, focusesForOrg: {}, unitPriceByOrgAndFocus: {} };
  if (productCatalog) {
    for (const product of productCatalog?.products) {
      const orgId = product.dedicatedCoachingMetadata?.orgId;
      const focus = product.dedicatedCoachingMetadata?.focus;
      const unitPrice = product.items?.[0]?.unitPrice;

      if (!orgId) continue;
      if (!dcCourseProducts.orgNamesById[orgId]) {
        dcCourseProducts.orgNamesById[orgId] = product.companyName;
      }

      if (!dcCourseProducts.focusesForOrg[orgId]) {
        dcCourseProducts.focusesForOrg[orgId] = new Set();
      }
      dcCourseProducts.focusesForOrg[orgId].add(focus);

      const unitPriceKey = `${orgId}${focus}`;
      if (
        !dcCourseProducts.unitPriceByOrgAndFocus[unitPriceKey] ||
        dcCourseProducts.unitPriceByOrgAndFocus[unitPriceKey] > unitPrice
      ) {
        dcCourseProducts.unitPriceByOrgAndFocus[unitPriceKey] = unitPrice;
      }
    }
  }

  const { orgNamesById, focusesForOrg } = dcCourseProducts;
  const dcCourseProductsReady = !!Object.keys(orgNamesById).length && !!Object.keys(focusesForOrg).length;

  return (
    dcCourseProductsReady && (
      <DCOrderDetails menteeId={menteeId} dcCourseProducts={dcCourseProducts} dcCourseId={dcCourseId} email={email} />
    )
  );
};
